<template>
  <div class="login-container">
    <div class="login-content">
      <img src="@/assets/images/yijie.png" alt="Logo" class="logo">
      <div class="brand-name">宜解法务</div>
      <form @submit.prevent="login"> <!-- 恢复表单提交功能 -->
        <div class="input-container">
          <input v-model="accountNumber" type="text" placeholder="账号" required>
        </div>
        <div class="input-container">
          <input v-model="password" type="password" placeholder="密码" required>
        </div>
        <button type="submit" class="login-button">登录</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/axios'; // 导入 Axios 实例

export default {
  name: 'YijieLoginView',
  data() {
    return {
      accountNumber: '', // 使用 accountNumber 替代 username
      password: ''
    };
  },
  methods: {
    async login() {
      try {
        const response = await axios.post('/api/login', {
          accountNumber: this.accountNumber, // 使用 accountNumber 作为请求参数
          password: this.password
        });
        if (response.data.id) { // 假设返回用户信息中有 id 字段
          localStorage.setItem('loginSource', 'yijielogin'); // 在 login1 页面登录后保存来源信息
          localStorage.setItem('isAuthenticated', 'true'); // 保存登录状态
          localStorage.setItem('logoUrl',require('@/assets/images/yijie.png'))
          localStorage.setItem('brandName','宜解法务·AIBOSSOS')
          localStorage.setItem('userId', response.data.id); // 保存登录状态
          localStorage.setItem('botId', '613577183654318081'); // 保存登录状态
          localStorage.setItem('botType', 'legalBot'); // 保存登录状态
          localStorage.setItem('honorific','您好，我是宜解法务的AI助手，我有两大功能，帮助律师整理思路，帮助客户讨论法务问题，请问您怎么称呼？')
          localStorage.setItem('chatId','');
          localStorage.setItem('nameVerification','false');
          this.$router.push('/detail');
        } else {
          alert('登录失败');
        }
      } catch (error) {
        console.error('登录错误:', error);
        alert('登录错误');
      }
    }
  }
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;
}

.login-content {
  background-color: transparent;
  padding: 0;
  text-align: center;
}

.logo {
  height: 80px;
  margin-bottom: 10px;
}

.brand-name {
  font-size: 24px;
  color: #333;
  margin-bottom: 30px;
}

.input-container {
  margin-bottom: 10px;
}

.input-container input {
  width: 300px;
  height: 40px;
  padding: 10px;
  border: 2px solid #ddd;
  border-radius: 4px;
  outline: none;
  font-size: 16px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1); /* 添加内阴影 */
}

button[type="submit"] {
  width: 314px;
  height: 50px;
  background-color: transparent;
  border: none;
  color: #333;
  font-size: 20px;
  margin-top: 50px;
  cursor: pointer;
}

/* 新的登录按钮样式 */
.login-button {
  width: 300px;
  height: 50px;
  background-color: #f5f5f5;
  border: none;
  color: #333;
  font-size: 18px;
  font-weight: bold;
  border-radius: 25px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-align: center;
  line-height: 50px;
  margin-top: 20px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.login-button:hover {
  background-color: #e5e5e5;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}
</style>
