<template>
  <div class="chat-detail-container">
    <header class="header">
      <div class="header-left">
        <img :src="logoSrc" alt="Logo" class="logo" @click="toggleLogoutConfirm" />
        <span class="brand-name">{{ brandName }}</span>
      </div>
      <div class="header-right">
        <img src="@/assets/images/chat.png" alt="Home" class="home-icon" @click="newChat" />
        <div class="divider"></div>
        <img src="@/assets/images/history.png" alt="Settings" class="settings-icon" @click="goHistory" />
      </div>
    </header>

    <!-- 退出确认弹窗 -->
    <div v-if="showLogoutConfirm" class="logout-confirm">
      <div class="confirm-box">
        <p>是否退出登录?</p>
        <div class="confirm-buttons">
          <button @click="logout">确认</button>
          <button @click="toggleLogoutConfirm">取消</button>
        </div>
      </div>
    </div>

    <div class="messages-list">
      <div v-for="message in messages" :key="message.id" :class="['message', message.type]">
        <img v-if="!message.loading && message.avatar" :src="message.avatar" alt="" class="avatar"/>
        <div class="message-content">
          <div class="message-bubble">
            <!-- 使用 v-html 来处理消息内容并显示换行 -->
            <span class="message-text" v-if="!message.loading" v-html="formatContent(message.content)"></span>
            <div class="loading-dots" v-else>
              <span></span><span></span><span></span>
            </div>
          </div>
          <span class="message-time">{{ message.time }}</span>
        </div>
      </div>
      <div ref="scrollAnchor"></div>
    </div>
    <div class="message-input">
      <div class="input-container">
        <textarea v-model="newMessage" placeholder="请输入..." @keyup.enter="sendMessage"></textarea>
        <el-button class="send-button" @click="sendMessage" :disabled="!newMessage.trim()">
          <img src="@/assets/images/send.svg" alt="Send" />
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/axios'; // 导入 Axios 实例

export default {
  name: 'ChatDetailView',
  data() {
    return {
      chatTitle: 'ChatGPT',
      chatId: '', // 假设 chatId 是固定的，可以根据需要从后端获取或通过路由参数传递
      userId: '', // 当前用户的 ID，可以从用户登录信息中获取
      botuserId: '',
      messages: [], // 初始化为空列表，页面加载后从后端获取
      newMessage: '',
      loading: false,
      logoSrc: '',
      brandName: '',
      showLogoutConfirm: false, // 控制弹窗显示的状态
    };
  },
  mounted() {
    this.chatId = localStorage.getItem('chatId');
    this.setBranding();
    if(this.chatId !== '' && this.chatId != null) {
      this.loadChatHistory(); // 加载聊天记录
    }
  },
  methods: {
    async loadChatHistory() {
      try {
        const response = await axios.post('/session/chat/selectChatMessage?chatId=' + this.chatId);
        if (response.data) {
          this.userId = response.data.userId1;
          this.botuserId = response.data.userId2;
          this.chatId = response.data.chatId;
          this.messages = response.data.messages.map(message => ({
            id: Date.now(),  // 使用 Date.now() 作为唯一 ID 不够理想，最好使用消息中的唯一 ID
            sender: message.sender,
            content: message.content,
            time: new Date(message.timestamp).toLocaleTimeString(),
            type: message.sender === this.userId ? 'sent' : 'received',
          }));
          this.scrollToBottom();
        }
      } catch (error) {
        console.error('Error loading chat history:', error);
      }
    },
    toggleLogoutConfirm() {
      this.showLogoutConfirm = !this.showLogoutConfirm;
    },
    logout() {
      const loginSource = localStorage.getItem('loginSource');
      this.$router.push(`/${loginSource}`); // 将 loginSource 作为路由地址跳转

      // 清除所有缓存数据
      localStorage.clear();

      // 如果需要立即刷新页面或跳转到首页，可以添加如下行：
      // window.location.reload(); // 可选：刷新页面
    },
    setBranding() {
      if(localStorage.getItem('loginSource') == 'yijieLogin'){
        localStorage.setItem('nameVerification','false');
      }
      const logoUrl = localStorage.getItem('logoUrl');
      this.logoSrc = logoUrl;
      this.brandName = localStorage.getItem('brandName');
      this.userId = localStorage.getItem('userId');
      this.botuserId = localStorage.getItem('botId');
      // 在页面加载时生成一个欢迎消息
      this.messages.push({
        id: Date.now(),
        sender: localStorage.getItem('botId'),
        content: localStorage.getItem('honorific'),
        timestamp: new Date().toLocaleTimeString(),
        type: 'received',
      });
    },
    newChat(){
      localStorage.setItem('chatId','')
      window.location.reload();//新聊天
    },
    goHistory() {
      localStorage.setItem('chatId', this.chatId);
      this.$router.push('/list'); // 假设 '/' 是主页路由
    },
    goBack() {
      this.$router.go(-1);
    },
    formatContent(content) {
      return content.replace(/\n/g, '<br>');
    },
    async sendMessage() {
      if (this.newMessage.trim() !== '') {
        const chatId = this.chatId;
        const sender = this.userId;
        const time = new Date().toISOString();

        const userMessage = {
          id: Date.now(),
          sender: sender,
          content: this.newMessage,
          timestamp: time,
          type: 'sent',
        };

        this.messages.push({
          ...userMessage,
          time: new Date(time).toLocaleTimeString(),
        });

        this.scrollToBottom();
        const userInput = this.newMessage;
        this.newMessage = ''; // 清空输入框

        const loadingMessage = {
          id: Date.now() + 1,
          text: '',
          time: new Date().toLocaleTimeString(),
          type: 'received',
          loading: true,
        };

        this.messages.push(loadingMessage);
        this.loading = true;

        this.scrollToBottom();

        try {
          const lastMessages = this.messages
            .slice(-4)
            .filter(message => message.content && message.content.trim() !== '')
            .map(message => ({
              sender: message.sender,
              content: message.content,
              timestamp: message.timestamp,
            }));

            // const lastMessages = this.messages
            // .filter(message => message.content && message.content.trim() !== '')
            // .map(message => ({
            //   sender: message.sender,
            //   content: message.content,
            //   timestamp: message.timestamp,
            // }));

          let botMessage;
          if(localStorage.getItem('nameVerification')=='false'){
              const response = await axios.post('/api/chatgpt/askName', { 
              input: userInput,
              history: lastMessages,
              type: localStorage.getItem('botType') // 新增的type字段，假设此处为'legal'
            });
            let quest = '';
            console.log(response.data.choices[0].message.content);
            if(response.data.choices[0].message.content == 'true'){
              quest = '很高兴能够帮你解答法律问题，可以告诉我您的需求吗？'
              localStorage.setItem('nameVerification','true')
            }else{
              quest = '抱歉，我需要了解您的姓名后才可继续为您服务！'
            }
            botMessage = {
              id: Date.now() + 2,
              sender: this.botuserId,
              content: quest,
              timestamp: new Date().toISOString(),
              type: 'received',
              loading: false,
            };
          }else{
              const response = await axios.post('/api/chatgpt/ask', { 
              input: userInput,
              history: lastMessages,
              type: localStorage.getItem('botType') // 新增的type字段，假设此处为'legal'
            });

            botMessage = {
              id: Date.now() + 2,
              sender: this.botuserId,
              content: response.data.choices[0].message.content,
              timestamp: new Date().toISOString(),
              type: 'received',
              loading: false,
            };
          }

          this.messages.pop();
          this.messages.push({
            ...botMessage,
            time: new Date(botMessage.timestamp).toLocaleTimeString(),
          });

          this.scrollToBottom();

          const fullResponse = await axios.post('/session/chat/update?chatId=' + chatId + '&userId1=' + this.userId + '&userId2=' + this.botuserId + '&message=' + encodeURIComponent(JSON.stringify({
            sender: userMessage.sender,
            content: userMessage.content,
            timestamp: userMessage.timestamp,
          })));
          const newChatId = fullResponse.data.split("chatId:")[1];
          this.chatId = newChatId;

          await axios.post('/session/chat/update?chatId=' + this.chatId + '&userId1=' + this.userId + '&userId2=' + this.botuserId + '&message=' + encodeURIComponent(JSON.stringify({
            sender: botMessage.sender,
            content: botMessage.content,
            timestamp: botMessage.timestamp,
          })));

        } catch (error) {
          console.error('Error:', error);
          this.messages.pop();
        } finally {
          this.loading = false;
        }
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const anchor = this.$refs.scrollAnchor;
        if (anchor) {
          anchor.scrollIntoView({ behavior: 'smooth' });
        }
      });
    }
  }
};
</script>


<style scoped>
/* 设置html和body的基础样式，确保宽度和高度占满全屏 */
html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  box-sizing: border-box;
}

/* 设置全局的盒模型属性 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/* 整个页面容器的样式 */
.chat-detail-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw; /* 确保宽度占满全屏 */
  overflow: hidden;
  background-color: #f5f5f5;
}

/* 修改后的页面头部样式 */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* 左右两侧内容分散对齐 */
  background-color: #ffffff;
  padding: 10px 20px; /* 两边增加内边距 */
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #e0e0e0;
  box-sizing: border-box;
}

.header-left {
  display: flex;
  align-items: center;
}

.logo {
  width: 40px;
  height: 40px;
  margin-right: 10px; /* 右侧增加间距 */
}

.brand-name {
  font-size: 16px;
  color: #333;
}

.header-right {
  display: flex;
  align-items: center;
}

.home-icon {
  width: 32px; /* 设置 Home 图标的宽度 */
  height: 32px; /* 设置 Home 图标的高度 */
  cursor: pointer;
}

.divider {
  width: 1px;
  height: 32px; /* 和图标高度一致 */
  background-color: #ccc; /* 设置分隔符颜色 */
  margin: 0 10px; /* 设置与图标的间距 */
}

.settings-icon {
  width: 32px; /* 设置 Settings 图标的宽度 */
  height: 32px; /* 设置 Settings 图标的高度 */
  cursor: pointer;
}

/* 消息列表的样式 */
.messages-list {
  flex: 1;
  padding: 10px;
  background-color: #f5f5f5;
  width: 100%; /* 确保宽度占满全屏 */
  box-sizing: border-box; /* 确保padding包含在宽度内 */
  overflow-y: auto; /* 启用垂直滚动条 */
  overflow-x: hidden; /* 禁用水平滚动条 */
}

/* 消息项的样式 */
.message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

/* 收到消息的样式 */
.message.received {
  justify-content: flex-start;
}

/* 发送消息的样式 */
.message.sent {
  flex-direction: row-reverse; /* 反转方向，使头像在右边 */
}

/* 头像的样式 */
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px;
}

/* 消息内容的样式 */
.message-content {
  max-width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.message.sent .message-content {
  align-items: flex-end;
}

/* 消息气泡的样式 */
.message-bubble {
  position: relative;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 5px;
}

.message.received .message-bubble {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
}

.message.sent .message-bubble {
  background-color: #9eea6a;
  border: 1px solid #9eea6a;
}

/* 消息气泡的尖角样式 */
.message-bubble::before {
  content: '';
  position: absolute;
  top: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
}

.message.received .message-bubble::before {
  left: -12px;
  border-right-color: #ffffff;
}

.message.sent .message-bubble::before {
  right: -12px;
  border-left-color: #9eea6a;
}

/* 消息时间的样式 */
.message-time {
  font-size: 12px;
  color: #888;
  text-align: right;
}

/* 输入框区域的样式 */
.message-input {
  display: flex;
  align-items: center;
  justify-content: center; /* 使输入框容器居中 */
  padding: 10px;
  width: 100%; /* 确保宽度占满全屏 */
  box-sizing: border-box; /* 确保padding包含在宽度内 */
}

/* 输入框和发送按钮的容器 */
.input-container {
  display: flex;
  align-items: center;
  width: 100%; /* 设置输入框容器的宽度 */
  background-color: #ffffff;
  border-radius: 25px; /* 设置圆角 */
  border: 1px solid #e0e0e0;
  padding: 5px 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
}

/* 输入框的样式 */
.input-container textarea {
  flex: 1;
  border: none;
  outline: none;
  resize: none; /* 禁用拖动改变大小 */
  padding: 10px;
  font-size: 16px;
  background-color: transparent; /* 设置透明背景 */
  border-radius: 20px;
  height: 40px; /* 固定高度 */
}

/* 发送按钮的样式 */
.send-button {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* 发送按钮中的图标样式 */
.send-button img {
  width: 24px;
  height: 24px;
}

/* 禁用发送按钮时的样式 */
.send-button.disabled {
  opacity: 0.5; /* 设置透明度 */
  pointer-events: none; /* 禁用点击事件 */
}

/* 加载中点点的样式 */
.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-dots span {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background-color: #999;
  border-radius: 50%;
  animation: loading 1.2s infinite;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.4s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.8s;
}

.logout-confirm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirm-box {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.confirm-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  gap: 10px; /* 添加按钮之间的间隔 */
}

.confirm-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirm-buttons button:first-child {
  background-color: #d9534f;
  color: white;
}

.confirm-buttons button:last-child {
  background-color: #5bc0de;
  color: white;
}

/* 加载动画的关键帧 */
@keyframes loading {
  0% {
    transform: scale(1);
    background-color: #999;
  }
  50% {
    transform: scale(1.5);
    background-color: #333;
  }
  100% {
    transform: scale(1);
    background-color: #999;
  }
}
</style>
